var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label)?_c('label',{staticClass:"text-xs pl-3 text-left block mb-1"},[_vm._v(_vm._s(_vm.label)+" "),(_vm.asterix)?_c('span',{staticClass:"text-red-500"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('input',_vm._b({staticClass:"focus:outline-none focus:ring-2 focus:ring-tosca w-full rounded-lg",class:[
      _vm.withIcon ? 'pl-11' : _vm.paddingLeft[_vm.size],
      _vm.fieldClass[_vm.size],
      _vm.additionalClass,
      { 'text-center': _vm.center },
      { 'is-invalid': _vm.hasErrors, 'bg-grey cursor-not-allowed': _vm.disabled, 'border-grey-field border': _vm.borderEnabled, 'bg-white': _vm.disabledWhiteBg }
    ],attrs:{"disabled":_vm.disabled || _vm.disabledWhiteBg},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enterKeyAction()}}},'input',_vm.$attrs,false)),(_vm.importantNotes && _vm.notes)?_c('div',{staticClass:"arrow text-xs leading-tight inline-block p-2"},[_vm._v(_vm._s(_vm.notes))]):_c('span',{staticClass:"text-xs text-gray-500 mt-1 leading-tight inline-block"},[_vm._v(_vm._s(_vm.notes))]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.error)?_c('div',{staticClass:"text-system-error text-left mt-1 text-xs"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }