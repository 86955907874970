export default {
  props: {
    buttonText: {
      type: String,
      required: true
    },
    disabled: {
      type: [Boolean, String],
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'regular'
    },
    additionalClass: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    buttonClass: {
      primary: 'bg-yellow text-white border-yellow hover:bg-yellow-300 hover:border-yellow-300',
      secondary: 'bg-yellow-secondary border-yellow-secondary text-yellow hover:text-yellow-300',
      tertiary: 'bg-transparent border-yellow text-yellow hover:text-yellow-300 hover:border-yellow-300',
      primaryGrey: 'bg-grey-400 text-white border-neutral-400 hover:bg-neutral-200 hover:border-neutral-200',
      tertiaryGrey: 'bg-transparent border-neutral-400 text-neutral-400 hover:text-neutral-200 hover:border-neutral-200',
      disableGrey: 'bg-grey-field text-white border-grey-field cursor-not-allowed'
    },
    buttonSizeClass: {
      regular: 'text-sm py-2 px-6',
      regularFull: 'text-sm py-2 px-4 w-full',
      big: 'text-base py-3 px-6',
      bigFull: 'text-base py-3 px-6 w-full',
      full: 'text-base py-2 px-6 w-full',
      small: 'text-xs py-1 px-3',
      narrow: 'text-sm py-2 px-4',
      smallNarrow: 'text-xs py-2 px-4'
    }
  }),
  computed: {
    buttonType() {
      return this.buttonClass[this.type]
    },
    buttonSize() {
      return this.buttonSizeClass[this.size]
    }
  },
  methods: {
    handleClick() {
      this.$emit('action')
    }
  }
}
